<template>
  <v-container
    style="max-width: 800px"
    class="mx-auto"
    >
    <v-card
      outlined
      >
      <v-card-title
        class="justify-center"
        >
        <span
          v-if="$route.params.orderToken == 'aborted'"
          >
          Orden de Compra cancelada
        </span>
        <span
          v-else
          >
          Orden de Compra {{ (order || {}).number }} rechazada
        </span>
      </v-card-title>

      <v-card-text
        class="black--text"
        >
        <div
          v-if="$route.params.orderToken == 'aborted'"
          >
          El pago de su orden de compra ha sido cancelado, ya sea de forma manual o debido a que transcurrió un tiempo de espera mayor al máximo permitido por WebPay hasta completar el formulario de pago.
        </div>
        <div
          v-else
          >
          Las posibles causas de este rechazo son:
          <br />
          * Error en el ingreso de los datos de su tarjeta de crédito o débito (fecha y/o código de seguridad).
          <br />
          * Su tarjeta de crédito o débito no cuenta con saldo suficiente.
          <br />
          * Tarjeta aún no habilitada en el sistema financiero.
        </div>
      </v-card-text>

      <v-card-actions
        class="justify-center"
        >
        <v-btn
          color="secondary"
          :to="{ name: 'checkout' }"
          >
          Volver a intentar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { Order } from '@/graphql/queries/orders'

const Detail = () => import(/* webpackPrefetch: true */ "@/components/orders/Detail");

export default {
  data () {
    return {
      order: null
    }
  },

  components: {
    Detail
  },

  mounted () {
    this.fetchOrder()
  },

  methods: {
    fetchOrder () {
      this.$apollo.query({
        query: Order,
        variables: {
          orderToken: this.$route.params.orderToken
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.order = res.data.order
      })
    }
  }
}
</script>
